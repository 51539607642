import { defineStore } from 'pinia'
import type { Ref } from 'vue'

const TUTORIAL_STATUS = [
  'NONE',
  'INIT',
  'START',
  'PICTURE',
  'TREASURE',
  'FOOD',
  'BLOCK_PICTURE',
  'BLOCK_TREASURE',
  'BLOCK_FOOD',
  'BACK' // 이전 상태로 변경
] as const
type StatusType = (typeof TUTORIAL_STATUS)[number]

function getPinkMessages(val: StatusType) {
  switch (val) {
    case 'INIT':
      return `헬로메이플을 체험해보세요!`
    case 'START':
      return `<em class="em">아이콘</em>을 눌러서<br/>퀘스트를 시작해봐!`
    case 'PICTURE':
      return `예쁜 추억을 사진으로 남겼어!`
    case 'TREASURE':
      return `도시락을 찾았어! 축하해~`
    case 'FOOD':
      return `맛있는 점심 식사로<br/>즐거운 소풍 마무리~`
    // case 'BACK':
    case 'BLOCK_PICTURE':
    case 'BLOCK_TREASURE':
    case 'BLOCK_FOOD':
      return `<em>알맞은 블록</em>을 맞추고,<br/>친구를 도와주자!`
    default:
      return null
  }
}

export const useGameStore = defineStore('game', () => {
  const isPlaying = ref(false)
  const tutorialStatus: Ref<StatusType> = ref('INIT')
  const pinkMessage = computed(() => {
    return getPinkMessages(tutorialStatus.value) || ''
  })
  function changeTutorialStatus(status: StatusType) {
    tutorialStatus.value = status
  }
  const getTutorialStatus = computed(() => {
    return tutorialStatus.value.toLocaleLowerCase()
  })
  return { isPlaying, pinkMessage, changeTutorialStatus, getTutorialStatus }
})
