<template>
  <div class="section_tutorial">
    <div class="content_main">
      <div class="img_message font_maple_story">
        <!-- eslint-disable-next-line -->
        <em>코딩 블록</em>을 조합해<br/>이야기를 완성해 보세요!
        <atoms-c-image
          class="bg_block left"
          width="221"
          height="177"
          file-name="main-tutorial-cloud"
          :density="3"
        />
        <atoms-c-image
          class="bg_block right"
          width="176"
          height="130"
          file-name="main-tutorial-cloud-2"
          :density="3"
        />
      </div>
      <div class="area_canvas" :class="gameStore.getTutorialStatus">
        <div
          v-show="showYetiMessage"
          class="text_pink pc_only"
          :class="gameStore.getTutorialStatus"
          v-html="gameStore.pinkMessage"
        ></div>
        <div class="box_canvas">
          <div class="board">
            <div class="bg_tutorial_mo mo_only">
              <!-- eslint-disable-next-line -->
              <div class="txt_play">
                <em>PC</em>
                에서 헬로메이플을
                <br />
                체험해보세요!
              </div>
            </div>
            <div id="wrap_phaser" class="wrap_phaser pc_only">
              <client-only>
                <phaser-game
                  v-if="createGameFunc"
                  :create-game="createGameFunc"
                ></phaser-game>
              </client-only>
              <div v-show="!isPlaying" class="float_play">
                <!-- eslint-disable-next-line -->
                <div class="txt_play">
                  헬로메이플을 체험해볼까요?
                  <br />
                  <em>아래의 PLAY 버튼</em>
                  을 눌러 체험을 시작해보세요!
                </div>
                <button
                  v-log-a2s-click
                  class="btn_play"
                  :disabled="!createGameFunc"
                  data-a2s-obj-name="Main_TutorialPlay"
                  @click="onClickPlay"
                >
                  PLAY
                </button>
              </div>
            </div>
            <div class="board_title">
              <div class="board_clip"></div>
              <span>두근두근 소풍 날</span>
              <div class="board_clip"></div>
            </div>
            <div class="board_reg left"></div>
            <div class="board_reg right"></div>
          </div>
          <atoms-c-image
            class="img_yeti"
            file-name="main-tutorial-yeti"
            :density="2"
          />
          <atoms-c-image
            class="img_mushroom"
            file-name="main-tutorial-mushroom"
            :density="2"
          />
        </div>
        <div class="box_block pc_only">
          <div class="area_quiz">
            <img src="@/assets/images/tutorial/block_main.svg" alt="block" />
            <draggable-component
              v-model="answers"
              class="area_into_drag"
              item-key="id"
              group="block"
              :move="movableBlock"
              @change="onChangeBlock"
            >
              <template #item="{ element }">
                <div class="img_block" :class="element.id" />
              </template>
            </draggable-component>
            <img
              v-show="showGuideBlock"
              class="img_transparent_block"
              src="@/assets/images/tutorial/block_transparent.svg"
              alt="block"
            />
          </div>
          <div class="area_answer">
            <draggable-component
              v-for="(block, i) in blocks"
              :key="i"
              v-model="blocks[i]"
              item-key="id"
              :group="{ name: 'block', put: false }"
              class="el_drag"
              :move="movableBlock"
            >
              <template #item="{ element }">
                <div>
                  <div
                    class="img_block"
                    :class="[
                      element.id,
                      { highlight: gameStore.getTutorialStatus === element.id }
                    ]"
                  />
                </div>
              </template>
            </draggable-component>
            <img
              v-show="showGuideBlock"
              class="img_finger"
              :class="gameStore.getTutorialStatus"
              src="@/assets/images/tutorial/hand_motion.gif"
              alt="finger"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="img_bottom"></div>
  </div>
</template>

<script lang="ts" setup>
import draggableComponent from 'vuedraggable'
import { PhaserGame } from 'nuxtjs-phaser'
import { useGameStore } from '~/stores/game'
import type { Ref } from 'vue'
type BlockInfo = {
  id: string
  nextStatus: string
  successA2sObj: string
}
const INIT_BLOCKS: BlockInfo[][] = [
  [
    {
      id: 'block_food',
      nextStatus: 'food',
      successA2sObj: 'Main_TutorialLunch'
    }
  ],
  [
    {
      id: 'block_treasure',
      nextStatus: 'treasure',
      successA2sObj: 'Main_TutorialTreasure'
    }
  ],
  [
    {
      id: 'block_picture',
      nextStatus: 'picture',
      successA2sObj: 'Main_TutorialPhoto'
    }
  ]
]
const createGameFunc = ref()
const isPlaying = ref(false)
const gameStore = useGameStore()
const answers: Ref<BlockInfo[]> = ref([])
const blocks = ref([...INIT_BLOCKS])
const { $sendLog } = useNuxtApp()

watch(
  () => gameStore.getTutorialStatus,
  (val, oldVal) => {
    if (val === 'none' || val === 'back') {
      answers.value = []
      blocks.value = [...INIT_BLOCKS]
    }
    if (val === 'back') {
      // @ts-ignore: TODO @gykim refactor 필요
      gameStore.changeTutorialStatus(oldVal.toUpperCase())
    }
  }
)

/**
 * 블럭을 맞춰야할 때 가이드 효과 표시
 */
const showGuideBlock = computed(() => {
  return (
    gameStore.getTutorialStatus.startsWith('block_') &&
    answers.value.length === 0
  )
})

/**
 * 예티 말풍선 표시 여부
 * - 할말이 있으면서 정답을 맞춘 상태가 아닐 때 표시
 */
const showYetiMessage = computed(() => {
  return (
    gameStore.pinkMessage &&
    answers.value[0]?.id !== gameStore.getTutorialStatus
  )
})

/**
 * 블록 이동 가능 여부
 */
function movableBlock() {
  return showGuideBlock.value
}

/**
 * 정답 영역에 블럭이 추가되었을 때
 * @param changeInfo
 */
function onChangeBlock(changeInfo: any) {
  if (changeInfo?.added) {
    const blockInfo = changeInfo?.added.element
    if (blockInfo.id === gameStore.getTutorialStatus) {
      phaserEmit(blockInfo.nextStatus, `${blockInfo.nextStatus}-scene`)
      $sendLog(blockInfo.successA2sObj, undefined, 'contents')
    } else {
      const currentScene = gameStore.getTutorialStatus.split('block_')[1]
      phaserEmit(`${currentScene}-fail`, `${currentScene}-scene`)
    }
  }
}
function phaserEmit(eventName: string, scene: string) {
  NuxtPhaser.eventEmitter?.emit(eventName, scene)
}
async function getGame() {
  const { createGame } = await import('@/game/game')
  return createGame
}
function onClickPlay() {
  if (createGameFunc.value) {
    isPlaying.value = true
    gameStore.changeTutorialStatus('START')
    phaserEmit('start', 'intro-scene')
  }
}
onMounted(async () => {
  createGameFunc.value = await getGame()
})
onUnmounted(() => {
  NuxtPhaser.game?.destroy(true)
})
</script>

<style lang="scss" scoped>
.section_tutorial {
  background-color: #dcffea;
  padding-top: 92px;
  height: 900px;
  overflow: hidden;
  box-sizing: border-box;
  position: relative;
  .img_message {
    height: 116px;
    position: relative;
    text-align: center;
    font-size: 48px;
    line-height: 1.4;
    letter-spacing: -1.92px;
    em {
      color: #4a88ff;
    }
    .bg_block {
      position: absolute;
      &.left {
        left: calc(-221px - 330px);
      }
      &.right {
        top: 223px;
        right: calc(-469px - 176px);
      }
    }
  }
  .img_yeti {
    position: absolute;
    left: -24px;
    bottom: 41.5px;
    z-index: 2;
  }
  .img_mushroom {
    position: absolute;
    width: 121px;
    right: -255px;
    bottom: 43px;
    z-index: 2;
  }
  .img_finger {
    position: absolute;
    height: 32px;
    width: 32px;
    left: 180px;

    &.block_food {
      top: 50px;
    }
    &.block_picture {
      top: 170px;
    }
    &.block_treasure {
      top: 110px;
    }
  }

  .img_bottom {
    @include imageSet('assets/images/tutorial/bg_block');
    position: absolute;
    height: 143px;
    width: 100%;
    bottom: 0;
    z-index: 1;
    background-position: center calc(100% + 1px);
    background-repeat: no-repeat;
  }
  .area_canvas {
    margin-left: -8px;
    margin-top: -35px;
    display: flex;
    position: relative;
    height: 633px;
    justify-content: center;
    .text_pink {
      position: absolute;
      top: -13px;
      margin-left: 700px;
      color: #ffffff;
      border-radius: 50px;
      text-align: center;
      vertical-align: middle;
      background-color: #333547;
      flex-shrink: 0;
      padding: 15.5px 23.5px;
      font-size: 16px;
      font-weight: 700;
      line-height: 1.5;
      letter-spacing: -0.64px;
      &:after {
        border-top: 14px solid #333547;
        border-left: 28px solid transparent;
        border-right: 0 solid transparent;
        border-bottom: 0 solid transparent;
        content: '';
        position: absolute;
        bottom: -14px;
        right: 69px;
      }
    }
    .box_canvas {
      position: relative;
      width: 700px;
      margin-top: 107px;
      .wrap_phaser {
        background-color: black;
        width: 660px;
        height: 316px;
        border: 4px solid #ffffff;
        position: relative;
      }
      .board {
        border-radius: 8px;
        background: #ffd91d;
        box-shadow: 0 12px 0 0 #fcb500;
        padding: 42px 0 14px 0;
      }
      .board_title {
        position: absolute;
        top: -35px;
        border-radius: 50px;
        background: #da9022;
        box-shadow: 0 8px 0 0 #a96500;
        padding: 12px 0;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        gap: 24px;
        color: #ffffff;
        font-size: 20px;
        font-weight: 700;
        line-height: 1.4;
        letter-spacing: -0.8px;
        left: 0;
        right: 0;
        margin-left: auto;
        margin-right: auto;
        width: 228px;
        .board_clip {
          background-color: #a96500;
          border-radius: 50%;
          box-shadow: 0 2px 0 0 rgba(0, 0, 0, 0.25) inset;
          width: 8px;
          height: 8px;
        }
      }
      .board_reg {
        width: 48px;
        margin-top: 26px;
        position: absolute;
        background-color: #fcb500;
        &.left {
          left: 102px;
          height: 108px;
        }
        &.right {
          height: 96px;
          right: 95px;
          border-top: 12px solid rgba(0, 0, 0, 0.1);
        }
      }
    }
    .box_block {
      margin: 1px -77.37px 0 12px;
      padding-top: 115px;
      padding-right: 78px; // 핑크빈 꼬리 제외
      box-sizing: border-box;
      width: 308px;
      position: relative;
      background-repeat: no-repeat;
      @include imageSet('assets/images/tutorial/block_board');

      .img_block {
        cursor: grab;
        z-index: 3;
        height: 52px;
        background-position: center;
        background-repeat: no-repeat;
        &.block_food {
          background-image: url('assets/images/tutorial/block_food.svg');
          &.highlight {
            background-image: url('assets/images/tutorial/block_food_highlight.svg');
          }
        }
        &.block_picture {
          background-image: url('assets/images/tutorial/block_picture.svg');
          &.highlight {
            background-image: url('assets/images/tutorial/block_picture_highlight.svg');
          }
        }
        &.block_treasure {
          background-image: url('assets/images/tutorial/block_treasure.svg');
          &.highlight {
            background-image: url('assets/images/tutorial/block_treasure_highlight.svg');
          }
        }
      }
      .area_quiz {
        width: 230px;
        height: 160px;
        padding-top: 25px;
        text-align: center;
        box-sizing: border-box;
        position: relative;
        .img_block {
          z-index: 2;
          margin-top: -12px;
        }
        .img_transparent_block {
          position: absolute;
          left: 33px;
          top: 78px;
          animation: block-up 2s infinite;
        }
        @keyframes block-up {
          0% {
            opacity: 0;
            transform: translate3d(0, 50%, 0);
          }
          80%,
          100% {
            opacity: 1;
            transform: translateZ(0);
          }
        }
      }
      .area_answer {
        padding-top: 12px;
        width: 230px;
        height: 214px;
        text-align: center;
        position: relative;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        gap: 10px;
        .el_drag {
          height: 53px;
        }
      }

      .area_into_drag {
        height: 50px;
        width: 230px;
      }
    }
  }
  #wrap_phaser {
    width: 660px;
    height: 316px;
    display: flex;
    justify-content: center;
    text-align: initial;
  }
  .float_play {
    position: absolute;
    text-align: center;
    padding-top: 80px;
    .txt_play {
      font-size: 18px;
      line-height: 1.5;
      letter-spacing: -0.72px;
      padding: 32px 48px;
      border-radius: 8px;
      border: 4px solid #fff;
      background: rgba(245, 248, 255, 0.8);
      em {
        font-weight: 700;
        font-style: normal;
        color: #4b52ff;
      }
    }
    .btn_play {
      padding: 12px 40px;
      border-radius: 50px;
      border: 4px solid rgba(255, 255, 255, 0.1);
      background: #4b52ff;
      box-shadow: 0 4px 0 0 #2724b3;
      color: #ffffff;
      font-size: 16px;
      font-weight: 700;
      line-height: 1.5;
      letter-spacing: -0.64px;
      cursor: pointer;
      margin-top: 10px;
      &:disabled {
        opacity: 0.1;
      }
    }
  }
}
@media (max-width: 999px) {
  .section_tutorial {
    padding-top: 60px;
    height: 580px;
    .img_mushroom {
      width: 80px;
      right: 0;
    }
    .img_yeti {
      left: -4px;
      width: 102.542px;
    }
    .img_message {
      width: 251px;
      height: 78px;
      font-size: 28px;
      line-height: 1.4;
      letter-spacing: -1.12px;
      .bg_block {
        &.left {
          top: 20px;
          left: -132px;
          width: 138px;
          height: 100.5px;
        }
        &.right {
          top: -19px;
          right: calc(-37px - 100px);
          width: 100px !important;
          height: 74.952px !important;
        }
      }
    }
    .area_canvas {
      margin: 0;
      height: 386px;
      .box_canvas {
        width: auto;
        margin-top: 36px;
        .board {
          padding: 32px 16px 13px;
          .board_title {
            top: -20px;
            box-shadow: 0 6px 0 0 #a96500;
            padding: 9.5px 0;
            gap: 12px;
            font-size: 15px;
            letter-spacing: -0.6px;
            width: 160px;
            .board_clip {
              width: 6px;
              height: 6px;
            }
          }
          .bg_tutorial_mo {
            border: solid 4px #ffffff;
            padding: 48px 54px 64px 54px;
            @include imageSet('assets/images/main-tutorial-bg');
            .txt_play {
              font-size: 16px;
              font-weight: 700;
              line-height: 1.5;
              letter-spacing: -0.64px;
              border-radius: 8px;
              border: 4px solid #fff;
              background: rgba(245, 248, 255, 0.9);
              width: 312px;
              padding: 16px 0;
              em {
                color: #4b52ff;
              }
            }
          }
          .board_reg {
            width: 36px;
            margin-top: 25px;
            &.left {
              left: 81px;
              height: 108px;
            }
            &.right {
              height: 96px;
              right: 80px;
            }
          }
        }
      }
    }
    .img_bottom {
      height: 103px;
      @include imageSet('assets/images/tutorial/mo_bg_block');
    }
  }
}
@media (max-width: 499px) {
  .section_tutorial {
    .content_main {
      padding: 0 20px;
    }
    .img_mushroom {
      left: unset;
      right: 0;
      bottom: 43px;
    }
    .img_yeti {
      left: 0;
    }
    .area_canvas {
      .box_canvas {
        width: 100%;
        .board {
          .bg_tutorial_mo {
            background-position: center;
            padding: 48px 16px 64px 16px;
            @include imageSet('assets/images/main-tutorial-bg');
            .txt_play {
              width: calc(100% - 32px);
            }
          }
        }
      }
    }
  }
}
</style>
<!-- 캔버스에서 사용할 DOM css -->
<style lang="scss">
.area_canvas {
  .text_top_speech {
    width: 512px;
    font-size: 14px;
    line-height: 1.5;
    color: #45454e;
    letter-spacing: -0.4px;

    .text_block {
      color: #ffffff;
      padding: 2px 6px;
      border-radius: 4px;
      font-weight: 700;
      line-height: 1.4;
      letter-spacing: -0.56px;
    }
  }
  .btn_quest {
    color: #ffffff;
    font-size: 14px;
    font-weight: 700;
    line-height: 1.6;
    letter-spacing: -0.56px;
    cursor: pointer;
  }
  &.block_treasure .text_block {
    background: #ff7cfa;
    box-shadow: 0 2px 0 0 #eb55e5;
  }
  &.block_food .text_block {
    background: #b852fe;
    box-shadow: 0 2px 0 0 #8200db;
  }
  &.block_picture .text_block {
    background: #88dc00;
    box-shadow: 0 2px 0 0 #75be00;
  }
}
.speech_text {
  border-image: url('assets/images/tutorial/speechbox.png') 10 / 6px round;
  border-width: 5px;
  border-style: solid;
  border-image-repeat: round;
  background-color: #eee;
  border-radius: 20px;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  padding: 10px 12px;
  color: $black0;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.4;
  letter-spacing: -0.56px;
  &:after {
    background-image: url('assets/images/tutorial/speechbox_tail.svg');
    background-repeat: no-repeat;
    width: 11px;
    height: 8px;
    content: '';
    position: absolute;
    bottom: -6px;
    left: 50%;
  }
}
.text_pink {
  &.start {
    em {
      color: #ffe130;
    }
  }
  &.block_treasure {
    em {
      color: #ff7cfa;
    }
  }
  &.block_food {
    em {
      color: #b852fe;
    }
  }
  &.block_picture {
    em {
      color: #88dc00;
    }
  }
}
</style>
